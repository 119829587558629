export default (theme) => ({
	selectBox: {
		marginBottom: 12,
		width: "100%",
	},
	menuItem: {
		paddingTop: 0,
		paddingBottom: 0,
	},
});
