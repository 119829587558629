import React, { useEffect } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { observer } from "mobx-react";
import { compose } from "recompose";

import withStores from "@framework/src/libs/withStores";
import useStores from "@hooks/useStores";

import withStyles from "@framework/src/libs/withStyles";
import useStyles from "@hooks/useStyles";

import Select from "@components/Global/Custom/Select";

import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

import { styles, stores } from "./.config";

const QuickPickQuestion = compose(
	withStyles(styles),
	withStores(stores),
	observer
)(({ question }) => {
	const classes = useStyles("QuickPickQuestion");
	const { quickPickQuestionStore: store } = useStores();
	const { "input.checkbox": checkboxAnswers, "input.radio": selectAnswers } =
		store.sortQuestionAnswersByType(question);
	const selectOnChangeHandler = (e) => {
		selectAnswers.forEach((answer) => store.removeSelectedAnswer(answer.key));
		if (e.target.value) store.addSelectedAnswer(e.target.value);
	};

	const checkboxOnChangeHandler = (value) => (e) => {
		e.preventDefault();
		if (!store.selection?.includes(value)) store.addSelectedAnswer(value);
		else store.removeSelectedAnswer(value);
	};

	useEffect(() => {
		store.init();
		return () => store.reset();
	}, []);

	return (
		<React.Fragment>
			{/*<div className="mb8 font-12 b8 black-200 letter-spacing-1">*/}
			{/*    {question.description.toUpperCase()}*/}
			{/*</div>*/}
			<div className={classes.selectBox}>
				{(checkboxAnswers || []).length > 0 && (
					<Select
						id={`question-selection-${question._id}`}
						multiple
						native={false}
						renderValue={(selected) => selected.join(", ")}
						value={
							store.checkboxAnswersDisplay(question) || [question.description]
						}
						inputProps={{ "aria-label": question.description }}
					>
						{checkboxAnswers.map((answer, i) => (
							<MenuItem
								key={`checkbox-menu-${answer.key}`}
								dense
								className={classes.menuItem}
								onClick={checkboxOnChangeHandler(answer.key)}
							>
								<Checkbox
									color={"primary"}
									id={answer.key}
									value={answer.key}
									checked={store.isChecked(answer.key)}
								/>
								<ListItemText primary={answer.value} />
							</MenuItem>
						))}
					</Select>
				)}
				{(selectAnswers || []).length > 0 && (
					<Select
						id={question.key}
						value={store.getSelectedAnswerKey(question)}
						onChange={selectOnChangeHandler}
						inputProps={{ "aria-label": question.description }}
					>
						<option value={""}>{question.description}</option>
						{selectAnswers.map((answer) => (
							<option key={answer.key} value={answer.key}>
								{answer.value}
							</option>
						))}
					</Select>
				)}
			</div>
		</React.Fragment>
	);
});

QuickPickQuestion.propTypes = {
	question: PropTypes.shape({
		key: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
	}).isRequired,
};

export default QuickPickQuestion;
