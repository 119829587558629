import { observable, computed, toJS, action } from "mobx";
import {
	base64EncodeUrl,
	base64decodeUrl,
	getParameterByName,
	replaceUrlParam,
} from "@utils/Browser";
import required from "@libs/required";

class QuickPickQuestionStore {
	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@observable _selection;
	@computed get selection() {
		return toJS(this._selection);
	}

	get encodedSelection() {
		if (!this.selection) return;
		return base64EncodeUrl(this.selection);
	}

	get decodedSelection() {
		const answers = getParameterByName("answers");
		if (answers) return JSON.parse(base64decodeUrl(answers));
		return;
	}

	checkboxAnswersDisplay(question = required`question`) {
		if (!this.selection) return;
		const selections = question.answers.filter(
			(answer) =>
				answer.type === "input.checkbox" &&
				this.selection.find((answerKey) => answer.key === answerKey)
		);
		if (!selections.length) return;
		return selections.map((answer) => answer.value);
	}

	@action reset() {
		this._selection = undefined;
	}

	@action
	init() {
		this._selection = this.decodedSelection;
	}

	sortQuestionAnswersByType(question = required`question`) {
		return question.answers.reduce((result, answer) => {
			if (!result[answer.type]) result[answer.type] = [];
			result[answer.type].push(answer);
			return result;
		}, {});
	}

	isChecked(answerKey = required`answerKey`) {
		if (!this.selection) return false;
		return !!this.selection.find((key) => key === answerKey);
	}

	getSelectedAnswerKey(question = required`question`) {
		if (!this.selection) return;
		const answer = question.answers.find((answer) =>
			this.selection.find((answerKey) => answer.key === answerKey)
		);
		return (answer || {}).key;
	}

	@action
	addSelectedAnswer(answerKey = required`answerKey`) {
		if (!this._selection) this._selection = [];
		this._selection.push(answerKey);
	}

	@action
	removeSelectedAnswer(answerKey = required`answerKey`) {
		if (!this.selection) return;
		this._selection = this.selection.filter((key) => key !== answerKey);
		if (this._selection.length === 0) this._selection = undefined;
	}
}

export default QuickPickQuestionStore;
