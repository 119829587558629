import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import MaterialSelect from "@material-ui/core/Select";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";

const useStyles = makeStyles({
	labelStyle: {
		display: "block",
		position: "relative",
	},
	selectStyle: {
		backgroundColor: "#FFF",
		fontWeight: "600",
	},
});

const Select = forwardRef(({ id, label, className, ...props }, ref) => {
	if (!id) id = shortid.generate();
	const classes = useStyles();

	return (
		<React.Fragment>
			{label && (
				<label className={classes.labelStyle} role="option" htmlFor={id}>
					{label}
				</label>
			)}
			<FormControl fullWidth={true}>
				<MaterialSelect
					native
					className={classes.selectStyle}
					id={id}
					ref={ref}
					{...props}
					variant="outlined"
					IconComponent={KeyboardArrowDownOutlinedIcon}
				>
					{props.children}
				</MaterialSelect>
			</FormControl>
		</React.Fragment>
	);
});

Select.propTypes = {
	className: PropTypes.string,

	children: PropTypes.any.isRequired,
};

export default Select;
